import React from "react";
import FlatButton from "../Reusables/FlatButton";
import styled from "styled-components";
import { Link } from "gatsby";
import ReactGA from 'react-ga';

const StyledWrapper = styled.div`

.full-height {
  height: 100%;
}

  .white-background {
    background-color: ${props => props.backgroundColor};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

`;

const Wrapper = styled.div`

  .white-background:hover {
    background-color: ${props => props.backgroundColor};
    color: white;
  }

  .white-background:hover .content-divider-cta-links {
    color: white;
  }

  .content-divider-cta-links {
    color: black;
    text-decoration: underline;
  }

  [class^=col] {
  display: flex;
  flex-direction: column;
}

[class^=col] div {
  flex-grow: 1
}

`;

const data = [
  {
    title: "STUDENT?",
    description:
      "We help students navigate career options and develop the skills and confidence to succeed through a free personalised programme of exclusive events and activities. Looking for support to explore your options, and to secure an internship or graduate role?",
    href: '/students',
    cta: "Apply Now",
    backgroundColor: "#E99034"
  },
  {
    title: "EMPLOYER?",
    description:
      "We help employers attract high calibre students from less-advantaged backgrounds. Our diverse partnerships are tailored to measure impact and facilitate long-term objectives. Searching for top talent?",
    href: '/employers',
    cta: "Find Out More",
    backgroundColor: "#464FD0"
  },
  {
    title: "UNIVERSITY?",
    description:
      "We support universities to achieve their progression goals by helping students raise their aspirations and develop employability skills, improving their chances of securing professional experience. Aiming to close the progression gap?",
      href: '/universities',
      cta: "Find Out More",
    backgroundColor: "#E43D5A"
  },
];

const ContentDividerBox = ({dividerData}) => {

  const handleStudent=()=>{
    ReactGA.event({
      category:"Homepage",
      action: "Find Out More - Students Click",
    });
  }
  const handleEmployer=()=>{
    ReactGA.event({
      category:"Homepage",
      action: "Find Out More - Employer Click",
    });
  }
  const handleUniversity=()=>{
    ReactGA.event({
      category:"Homepage",
      action: "Find Out More - University Click",
    });
  }

  return (
    <StyledWrapper>
    <div className="container-fluid margin-top-100">
      <div className="row">
        {React.Children.toArray(
          dividerData.map((item) => (
            <div className="col-12 col-lg-4 mt-3 mt-lg-0">
              <Wrapper className="full-height" backgroundColor={item.backgroundColor}>
              <div className="white-background padding-left-8 padding-right-8 py-5" >
                <h2>{item.title}</h2>
                <p className="subtext">{item.description}</p>
                <Link className="content-divider-cta-links" to={item.href}>
                <FlatButton> <div onClick={item.title === 'STUDENT?' ? handleStudent : item.title === 'EMPLOYER?' ? handleEmployer : item.title === 'UNIVERSITY?' ? handleUniversity : null}>{item.cta}</div> </FlatButton>
                </Link>
              </div>
              </Wrapper>
            </div>
          ))
        )}
      </div>
    </div>
    </StyledWrapper>
  );
};

export default ContentDividerBox;
