import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import EventSlider from "./EventSlider";
import rightArrow from "../../../images/carousel-arrow.svg";
import leftArrow from "../../../images/carousel-left-arrow.svg";

const StyledWrapper = styled.div`
.slider-slick {
  max-width: ${(props) => props.theme["percent-90"]};
  margin: auto;
  @media (max-width: 600px) {
    max-width: 100%;
  }
}

.slick-arrow {
  width: 72px;
  height: 72px;
  border: 0.25px solid rgba(151, 151, 151,0.5);
}

.slick-next:before,
.slick-prev:before {
  color: ${(props) => props.theme["borderBottomColor"]};
  font-size: ${props => props.theme['pixel-30']};
  font-weight: bolder;
  line-height: 0.5;
}

.slick-prev {
  left: -15.7%;
  z-index: 1;
  background-color: white;
  @media (max-width:450px){
    left: -9.7%;
  }
}

.slick-next {
  right: -15.7%;
  z-index: 1;
  background-color: white;
  @media (max-width:450px){
    right: -9.7%;
  }
}

.slick-next:hover, .slick-prev:hover {
  background-color: white;
}

.slick-prev:before {
  content: "";
}

.slick-next:before {
  content: "";
}

.custom-arrow{
  width:"17px;
  height:"29px;
}
`;

const events = [
    {
      title: "Student Social Mobility Awards",
      ceremonyType: "AWARD CEREMONY",
      date: "27",
      monthYear: "NOV 20",
      day: "Friday",
      endingText: "House of Lords",
    },
    {
      title: "Student Social Mobility Awards",
      ceremonyType: "AWARD CEREMONY",
      date: "27",
      monthYear: "NOV 20",
      day: "Friday",
      endingText: "House of Lords",
    },
    {
      title: "Student Social Mobility Awards",
      ceremonyType: "AWARD CEREMONY",
      date: "27",
      monthYear: "NOV 20",
      day: "Friday",
      endingText: "House of Lords",
    },
  ];

  function NextArrow(props) {
    const {className, style, onClick} = props;
    return (
      <div className={className} style={{...style,display:"flex",justifyContent:"center",alignItems:"center"}} onClick={onClick}>
        <img src={rightArrow} className="custom-arrow"/>
      </div>
    );
  }
  
  function PrevArrow(props) {
    const {className, style, onClick} = props;
    return (
      <div className={className} style={{...style,display:"flex",justifyContent:"center",alignItems:"center"}} onClick={onClick}>
        <img src={leftArrow} className="custom-arrow"/>
      </div>
    );
  }

export default function Carousel() {
  var settings = {
    speed: 500,
    slidesToShow: 1,
    infinity: true,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <StyledWrapper>
      <div className="container-fluid padding-left-8 padding-right-8">
        <Slider {...settings} className="slider-slick">
            {events.map((item) => (
            <EventSlider item={item}/>
            ))}
        </Slider>
      </div>
    </StyledWrapper>
  );
}
