import React from "react";
import styled from "styled-components";
import arrowActive from "../../../images/expand-arrow-active.svg"
import Carousel from "./HighlightCarousel"

const StyledWrapper = styled.div`
  .title {
    font-size: 19px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 30px;
  }

  .highlights-button-align{
    align-self:center;
    text-align-last:end;
  }

  .ceremony-type {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 1.87px;
    line-height: 27px;
  }

  .highlight-date {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }

  .highlight-img {
    object-fit: cover;
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
  }

  .hover-effect:hover {
    background-color: #304563;
    color: white;
  }

  .hover-effect:hover .circle-arrow {
    filter: brightness(0) invert(1);
  }

  .margin-right{
    margin-right:0px;
    background-color:#ffffff;
  }

  .border-class{
    border: 0.25px solid rgba(151, 151, 151,0.5);
  }

  .slider-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    @media(max-width:991px){
      margin-top: 20px;
      padding-top: 20px;
    }
  }

  .padding-right{
    @media(min-width:992px){
      padding-right: 1% !important;
    }
  }

  .highlights-block{
    @media(max-width:768px){
      padding-left: 8% !important;
      padding-right: 8% !important;
    }
  }

  .highlights-heading{
    font-size:34px;
    font-weight:900;
    @media (max-width: 600px){
      font-size:24px;
      font-weight:900;
    }
  }
`;

const HighlightsInfo = [
  {
    title: "Student Social Mobility Awards",
    ceremonyType: "AWARD CEREMONY",
    date: "10th November 2020",
  },
  {
    title: "Student Social Mobility Awards",
    ceremonyType: "AWARD CEREMONY",
    date: "10th November 2020",
  },
  {
    title: "Student Social Mobility Awards",
    ceremonyType: "AWARD CEREMONY",
    date: "10th November 2020",
  },
];

const Highlights = () => {
  return (
    <StyledWrapper>
      <div className="container-fluid margin-top-50 highlights-block">
      <div className="row padding-left-8 padding-right-8">
        <div className="highlights-heading mb-4">Recent Events</div>
      </div>
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="row padding-right">
              <div className="col-12 col-md-6 px-0">
                <img
                  className="highlight-img img-fluid d-none d-md-block"
                  src="https://i.picsum.photos/id/101/2621/1747.jpg?hmac=cu15YGotS0gIYdBbR1he5NtBLZAAY6aIY5AbORRAngs"
                  alt=""
                />
              </div>
              <div className="col-12 col-md-6 white-background">
                {React.Children.toArray(
                  HighlightsInfo.map((items) => (
                    <div className="row py-4 padding-left-8 border-class hover-effect">
                      <div className="col-10 col-md-8">
                        <p className="title">{items.title}</p>
                        <p className="ceremony-type">{items.ceremonyType}</p>
                        <p className="highlight-date">{items.date}</p>
                      </div>
                      <div className="col-2 col-md-4 highlights-button-align" onClick={() => window.location.href = "/events"}>
                        <img className="img-fluid circle-arrow" src={arrowActive} alt="" />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 slider-section px-0">
            <Carousel />
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default Highlights;
