import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`

  color: ${(props) => props.theme["primaryColor"]};

  .white-background {
    padding: ${(props) => props.theme["percent-10"]};
  }

  .event-title,
  .date,
  .day,
  .ending-text {
    font-weight: ${(props) => props.theme["footerLinkBold"]};
    letter-spacing: ${(props) => props.theme["zero"]};
  }

  .event-title {
    font-size: ${(props) => props.theme["fontSize-22"]};
    font-weight: ${(props) => props.theme["textBold"]};
    line-height: ${(props) => props.theme["StatisticTextLineHeight"]};
    @media (max-width: 600px) {
        font-size: ${(props) => props.theme["FooterLinkSize"]};
      }
  }

  .ceremony {
    font-size: ${(props) => props.theme["fontSize-20"]};
    font-weight: ${(props) => props.theme["headingBold"]};
    letter-spacing: 1.87px;
    line-height: ${(props) => props.theme["subTextLineHeight"]};
    @media (max-width: 600px) {
        font-size: ${(props) => props.theme["FooterLinkSize"]};
      }
  }

  .date {
    line-height: ${(props) => props.theme["line-height-38"]};
    color: ${(props) => props.theme["primaryColor"]};
  }

  .day {
    font-size: ${(props) => props.theme["fontSize-22"]};
    line-height: ${(props) => props.theme["line-height-24"]};
  }

  .ending-text {
    font-size: ${(props) => props.theme["fontSize-22"]};
    line-height: ${(props) => props.theme["StatisticTextLineHeight"]};
    @media (max-width: 600px) {
        font-size: ${(props) => props.theme["fontSize-20"]};
      }
  }

  .date-th {
      position: relative;
      top: -40px;
      @media (max-width: 600px) {
          top: -10px;
      }
  }

  .padding-class{
    padding-left:10% !important;
    @media (max-width: 1250px){
      padding-left: 13% !important;
    }
  }
`;

const EventSlider = ({ item }) => {
  return (
    <StyledWrapper>
      <div className="container-fluid">
        <div className="row">
              <div className="col-12 padding-class">
                  <p className="event-title">{item.title}</p>
                  <p className="ceremony">{item.ceremonyType}</p>
                  <p className="date statistic-number py-3 py-md-5">{item.date}<sup className="day date-th">th</sup></p>
                  <p className="month-year">{item.monthYear}</p>
                  <p className="day">{item.day}</p>
                  <p className="ending-text">{item.endingText}</p>
                </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default EventSlider;
