import React from "react";
import styled from "styled-components";
import arrowActive from "../../images/expand-arrow-active.svg"

const StyledWrapper = styled.div`

  .white-background {
    padding: ${(props) => props.theme["percent-20"]} ${(props) => props.theme["percent-10"]};
    z-index: 2;
  }

  .hover-background-img {
    position: absolute;
    top: ${(props) => props.theme["zero"]};
    left: ${(props) => props.theme["zero"]};
    width: ${(props) => props.theme["percent-100"]};;
    height: ${(props) => props.theme["percent-100"]};;
    object-fit: cover;
    opacity: ${(props) => props.theme["zero"]};
    z-index: -1;
    filter: brightness(50%);
  }

  .white-background:hover .hover-background-img {
    opacity: 1;
    transition: opacity 0.3s linear;
  }
  .white-background:hover .circle-arrow {
    filter: brightness(0) invert(1);
  }

  .white-background:hover .text-color-change {
    color: ${(props) => props.theme["secondaryColor"]};
  }

  .high-z-index {
    z-index: 3;
  }

  .date-writer {
    position: relative;
    top: -40px;
  }

  .date, .writer{
    font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  }

  .date {
    color: #626262;
  }

  .writer {
    color: #040404;
  }


`;

const HoverEffectBlocks = ({ program }) => {
  return (
    <StyledWrapper>
      <div className="col-12 white-background">
        <div className="high-z-index ">
          <div className="d-flex date-writer">
          <span className="mr-3 text-color-change date">{program.date}</span>
          <span className="text-color-change writer">{program.writer}</span>
          </div>
          <h1 className="text-color-change">{program && program.title}</h1>
          <p className="subtext text-color-change">
            {program && program.description}
          </p>
          <img className="img-fluid circle-arrow" src={arrowActive} alt="" />
        </div>
        <img
          className="hover-background-img"
          src="https://i.picsum.photos/id/0/5616/3744.jpg?hmac=3GAAioiQziMGEtLbfrdbcoenXoWAW-zlyEAMkfEdBzQ"
          alt=""
        />
      </div>
    </StyledWrapper>
  );
};

export default HoverEffectBlocks;
