import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import styled from "styled-components";
import FlatButton from "../Reusables/FlatButton";
import carouselArrowRight from "../../images/carousel-arrow.svg";
import carouselArrowLeft from "../../images/carousel-left-arrow.svg";
import ReactMarkdown from "react-markdown";
import DescriptionBlock from "../Home/DescriptionBlock"

import carousel1 from "../../images/pageImages/Homepage/Car.jpg";
import carousel2 from "../../images/pageImages/Homepage/Carousel2.jpg";

const carousel = [
  {
    description:
      "Winner of “Embracing Digital” Award in the 2020 Charity Governance Awards!",
    image: `${carousel1}`,
    cta: "Read More",
    href:
      "https://www.charitygovernanceawards.co.uk/short-list/upreach/?landing=winner",
    color: "#e43d5a",
  },
  {
    description: "upReach Impact Report 2018-19 out now.",
    image: `${carousel2}`,
    cta: "Read Full Report",
    href:
      "https://www.paperturn-view.com/uk/upreach/upreach-impact-report-2018-2019?pid=MTM131595&v=1.1",
    color: "#464FD0",
  },
];

const StyledWrapper = styled.div`
  .title-section {
    padding: 3% 8%;
    @media (max-width: 600px) {
      padding: 25px 8%;
      margin-top: 8px;
    }
  }

  .title {
    color: black;
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 45px;
    text-transform: unset;

    @media (max-width: 600px) {
      font-size: 15px;
      line-height: normal;
    }
  }

  .description-section {
    padding: 5% 3% 5% 8%;
    display: flex;
    max-height: 600px;
    flex-direction: column;
    justify-content: center;
    min-height: 162px;
    @media (max-width: 600px) {
      padding: 5% 2% 5% 5%;
    }
  }

  .image-section {
    max-height: 600px;
    min-height: 300px;
    @media (min-width: 600px) and (max-width: 991px) {
      height: 60vh;
    }

    @media (min-height: 992px) {
      height: 540px;
    }
  }

  .description {
    color: #ffffff;
    font-weight: lighter;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 40px;
    text-transform: unset;
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: normal;
      min-height: 159px;
    }

    @media (min-width: 601px) and (max-width: 991px) {
      min-height: 264px;
    }

    @media (min-width: 992px) {
      min-height: 440px;
    }
  }

  .description a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: bold;
  }

  .description strong {
    font-weight: bold;
  }

  .flat-button {
    color: white;
  }

  .home-carousel-img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  .slider-slick {
    max-width: ${props => props.theme["percent-100"]};
  }

  // Carousel Css

  .slider-slick {
    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  .slick-arrow {
    width: 72px;
    height: 72px;
    display: none !important;
  }

  .slider-slick:hover .slick-arrow {
    display: block !important;
  }

  .slick-next:before,
  .slick-prev:before {
    color: ${props => props.theme["borderBottomColor"]};
    font-size: ${props => props.theme["pixel-30"]};
    font-weight: bolder;
    line-height: 0.5;
  }

  .slick-prev {
    left: 0;
    z-index: 1;
    background-color: white !important;
  }

  .slick-next {
    right: 0;
    background-color: white !important;
  }

  .slick-next:hover,
  .slick-prev:hover {
    background-color: white !important;
  }

  .slick-prev:before {
    content: url(${carouselArrowLeft});
    transform: rotate(180deg);
  }

  .slick-next:before {
    content: url(${carouselArrowRight});
  }

  .carousel-links {
    text-decoration: none;
  }
  .home-desc .subtext {
    font-size: 24px !important;
    line-height: 40px !important;
  }
`;
function LinkRenderer(props) {
  return (
    <a href={props.href} target="_blank">
      {props.children}
    </a>
  );
}

const Home = ({ carouselData }) => {
  const mainUrl = process.env.GATSBY_API_URL;
  var settings = {
    speed: 500,
    slidesToShow: 1,
    infinity: true,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 7000,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  return (
    <StyledWrapper>
      <div className="container-fluid">
        <Slider {...settings} className="slider-slick">
          {React.Children.toArray(
            carouselData.map(item => (
              <div className="container-fluid">
                <div className="row">
                  <div
                    className="col-12 col-lg-5 col-xl-4 description-section padding-left-8"
                    style={{ backgroundColor: item.color }}
                  >
                    <h1 className="description d-flex align-items-start flex-column justify-content-center">
                      {/* <ReactMarkdown
                        renderers={{ link: LinkRenderer }}
                        source={item.description}
                      /> */}
                      <div className="home-desc">
                      <DescriptionBlock description={item.description}  />
                      </div>
                    </h1>
                    <div>
                      <Link
                        className="carousel-links"
                        target="_blank"
                        to={item.href}
                      >
                        <FlatButton className="find-more">
                          {item.cta}
                        </FlatButton>
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-lg-7 col-xl-8 p-0 image-section">
                    {item.image && (
                      <img
                        className="img-fluid home-carousel-img"
                        src={mainUrl + item.image.url}
                        alt="home-carousel-image"
                      />
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </Slider>
      </div>
    </StyledWrapper>
  );
};

export default Home;
