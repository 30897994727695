import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import HoverEffectBlocks from "./HoverEffectBlock"

const StyledWrapper = styled.div`
  margin-top: 100px;

      // Carousel Css

      .slider-slick {
    max-width: ${(props) => props.theme["percent-90"]};
    margin: auto;
    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  .slick-arrow {
    width: 72px;
    height: 72px;
    border: 0.25px solid #979797;
  }

  .slick-next:before,
  .slick-prev:before {
    color: ${(props) => props.theme["borderBottomColor"]};
    font-size: ${props => props.theme['pixel-30']};
    font-weight: bolder;
    line-height: 0.5;
  }

  .slick-prev {
    left: -6.7%;
    z-index: 1;
    background-color: white;
  }

  .slick-next {
    right: -6.7%;
    background-color: white;
  }

  .slick-next:hover, .slick-prev:hover {
    background-color: white;
  }

  .slick-prev:before {
    content: "<";
  }

  .slick-next:before {
    content: ">";
  }
`;

const carousel = [
  {
    writer: "upReach",
    date: "24 Oct 2020",
    description:
      "Sign up for the lorem ipsum dolor elementum et augue eget,volutpat sagittis velit. Nunc tempor turpis ut dictum gravida.",
  },
  {
    writer: "upReach",
    date: "24 Oct 2020",
    description:
      "Sign up for the lorem ipsum dolor elementum et augue eget,volutpat sagittis velit. Nunc tempor turpis ut dictum gravida.",
  },
  {
    writer: "upReach",
    date: "24 Oct 2020",
    description:
      "Sign up for the lorem ipsum dolor elementum et augue eget,volutpat sagittis velit. Nunc tempor turpis ut dictum gravida.",
  },
  {
    writer: "upReach",
    date: "24 Oct 2020",
    description:
      "Sign up for the lorem ipsum dolor elementum et augue eget,volutpat sagittis velit. Nunc tempor turpis ut dictum gravida.",
  },
];

export default function UpreachNewsCarousel() {
  var settings = {
    speed: 500,
    slidesToShow: 3,
    infinity: true,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
          centerMode: true,
          adaptiveHeight: true,
        },
      },
    ],
  };
  return (
    <StyledWrapper>
      <div className="container-fluid margin-top-100">
          <h1 className="padding-left-8 mb-4">UPREACH IN THE NEWS</h1>
        <Slider {...settings} className="slider-slick">
          {React.Children.toArray(carousel.map((item) => (
            <div className="px-1 px-md-3">
              <HoverEffectBlocks program={item} />
            </div>
          )))}
        </Slider>
      </div>
    </StyledWrapper>
  );
}
