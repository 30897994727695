import React from "react"
import FlatButton from "../Reusables/FlatButton"
import styled from "styled-components"
import ReactDOM from "react-dom"
import { Link } from "gatsby"
import ReactGA from 'react-ga'

const StyledWrapper = styled.div`

.get-involved-section {
  padding-top: 5%;
  padding-bottom: 5%;
}

  .get-involved {
    color: #ffffff;
    font-size: 42px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 50px;
    text-transform: unset;
    @media (max-width: 600px) {
      font-size: 24px;
      line-height: normal;
    }
  }

  .section1 {
    background-color: #464fd0;
  }

  .section2 {
    background-color: #9ea5ee;
  }

  .links {
    color: #030303;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    text-decoration: underline;
    @media (max-width: 600px) {
      font-size: 22px;
      line-height: normal;
    }
  }

  .link-text {
    @media (max-width: 600px) {
     font-size: 15px !important;
    }
  }

`

const GetInvolved = ({ data }) => {
 
  const handleDonate=()=>{
    ReactGA.event({
      category:"Homepage",
      action: "Get Involved Box - Donate Click",
    });
  }
  const handleVolunteer=()=>{
    ReactGA.event({
      category:"Homepage",
      action: "Get Involved Box - Volunteer Click",
    });
  }
  const handleMentor=()=>{
    ReactGA.event({
      category:"Homepage",
      action: "Get Involved Box - Mentor Click",
    });
  }
  const handleFunraise=()=>{
    ReactGA.event({
      category:"Homepage",
      action: "Get Involved Box - Funraise Click",
    });
  }
  const handleSubscribe=()=>{
    ReactGA.event({
      category:"Homepage",
      action: "Get Involved Box - Subscribe Click",
    });
  }
  return (
    <StyledWrapper>
      <div className="container-fluid margin-top-100">
        <div className="row">
          <div className="col-12 col-md-6 section1 d-flex flex-column justify-content-center padding-left-8 padding-right-8 get-involved-section pr-4">
            <h1 className="get-involved">{data.title}</h1>
          </div>
          <div className="col-12 col-md-6 get-involved-section section2 padding-left-8 padding-right-8">
            {data.links.map((link) => (
              <Link target={ link.externalLink ? "_blank" : null} className="links" to={link.href}> 
                <p className="link-text"  onClick={link.link === 'Donate' ? handleDonate : link.link === 'Volunteer' ? handleVolunteer : link.link === 'Mentor' ? handleMentor : link.link === 'Fundraise' ? handleFunraise : link.link === 'Subscribe to newsletter' ? handleSubscribe : null}> {link.link} </p>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default GetInvolved
