import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import aspire from "../../images/aspire.svg";
import brand from "../../images/brand.svg";
import employable from "../../images/employable.svg";
import rating from "../../images/rating.svg";
import social from "../../images/social.svg";

const StyledWrapper = styled.div`
  .grid-container {
    background-color: white;
    padding: 50px 0 50px 0;
  }

  .grid-container {
    display: flex;
  }

  .grid-flex {
    flex: 20%;
    @media (max-width: 600px) {
      flex: 100%;
    }
  }

  .feature-img,
  .short-text {
    @media (max-width: 600px) {
      margin-left: 8%;
      margin-right: auto;
    }
  }

  .short-text {
    color: #6e6e6e;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
  }

  .short-text-links:hover {
    text-decoration: none;
  }
`;

const Feature = [
  {
    logo: `${aspire}`,
    text: "Understand your post-18 options",
    href: "https://aspire.upreach.org.uk",
  },
  {
    logo: `${social}`,
    text: "Connect with professionals in your field",
    href: "http://socialmobilitynetwork.org.uk/",
  },
  {
    logo: `${rating}`,
    text: "Relative Education Attainment Level",
    href: "https://realrating.co.uk",
  },
  {
    logo: `${brand}`,
    text: "Recognising outstanding achievement",
    href: "https://studentsocialmobilityawards.org.uk",
  },
  {
    logo: `${employable}`,
    text: "Discover your employability",
    href: "https://getemployable.org",
  },
];

const FeatureSection = ({ featureData }) => {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper>
      <div className="container-fluid margin-top-100">
        <div className="row grid-container">
          {React.Children.toArray(
            featureData.map(items => (
              <div className="grid-flex d-flex flex-column my-4 my-lg-0">
                <Link
                  className="short-text-links"
                  target="_blank"
                  to={items.href}
                >
                  {items.logo && (
                    <img
                      className="feature-img mx-md-auto d-md-block"
                      src={mainUrl + items.logo.url}
                      alt=""
                    />
                  )}

                  <p className="text-md-center short-text mt-4">{items.text}</p>
                </Link>
              </div>
            ))
          )}
        </div>
      </div>
    </StyledWrapper>
  );
};

export default FeatureSection;
