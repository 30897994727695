import React from "react";
import logo from "../../images/university-logo.jpeg";
import styled, { keyframes } from "styled-components";
import ReactHtmlParser from "react-html-parser";
import ReactMarkdown from "react-markdown";
import DescriptionBlock from "../Home/DescriptionBlock"

const StyledWrapper = styled.div`
  background-color: white;
  padding-top: 50px;
  padding-bottom: 50px;

  .logo-line-height {
    max-width: 70%;
    width: 100%;
    margin: auto;
    @media (max-width: 600px) {
      line-height: 30px;
      max-width: 100%;
    }
  }

  .logo-description {
    max-width: 800px;
  }

  .logo-images {
    padding: 5px;
    height: 100%;
    max-height: 50px;
    object-fit: contain;
    margin: 25px auto 25px auto;
  }

  .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: flex;
    justify-content: center;
  }

  .bouncyJack {
    animation-name: floatBubble;
    animation-duration: 0.75s;
    animation-delay: 2s;
  }

  .react-desc-html a {
    text-decoration: underline;
    color: black;
  }

  @keyframes floatBubble {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      opacity: 1;
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      transform: scale3d(0.8, 0.8, 0.8);
    }

    60% {
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      transform: scale3d(0.95, 0.95, 0.95);
    }

    to {
      transform: scale3d(1, 1, 1);
    }
  }
`;

const LogoSection = ({ logoSectionHeading, logos }) => {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper>
      <div className="container-fluid margin-top-100">
        {logoSectionHeading && (
          <h1 className="text-center mb-4 padding-left-8 padding-right-8 logo-line-height">
            {logoSectionHeading.title}
          </h1>
        )}

        {logoSectionHeading && logoSectionHeading.description !== null && (
          <p className="subtext text-center logo-description d-block mx-auto mb-4 react-desc-html">
            {/* <ReactMarkdown source={logoSectionHeading.description} /> */}
            <DescriptionBlock description={logoSectionHeading.description}/>
          </p>
        )}
        <div className="row padding-left-8 padding-right-8 animated">
          {logos.length > 0 &&
            React.Children.toArray(
              logos.map(item => (
                <div
                  className="col-4 col-md-2 bouncyJack"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  data-aos-offset="500"
                >
                  {item.image && (
                    <img
                      className="img-fluid d-block logo-images"
                      src={mainUrl + item.image.url}
                      alt=""
                    />
                  )}
                </div>
              ))
            )}
        </div>
      </div>
    </StyledWrapper>
  );
};

export default LogoSection;
