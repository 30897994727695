import React, { useEffect, useRef, useState } from "react";
import FlatButton from "../Reusables/FlatButton";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import ReactGA from "react-ga";
import ReactHtmlParser from 'react-html-parser';


const StyledWrapper = styled.div`
  .home-summary-cta {
    text-decoration: none;
    color: black;
  }

  .mission {
    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }

  .subtext a {
    text-decoration: underline;
    color: black;
  }
  .subtext-line {
    text-decoration: underline;
    color: black;
    margin-top: -15px;
  }
  .Videoo{
    width: 425px;
    height: 239px;
    @media (max-width: 600px) {
      width: 355px;
      height: 200px;
    }
  }
  .content iframe {
    @media (min-width: 300px) and (max-width: 896px) {
      width: 324px;
      height: 180px;
    }
    @media (min-width: 200px) and (max-width: 280px) {
      width: 237px;
      height: 134px;
    }
  }

`;

const desc = val => {
  if (val == null) {
    return "";
  }
  return <ReactMarkdown source={val} escapeHtml={false}/>;
};


const SummaryBlock = (props) => {
  const summary = props.summary
  const desc_one = props.description
  const mainUrl = process.env.GATSBY_API_URL;
  const inputRef = useRef(null);

  const lines = summary ? summary.description.split('\n') : desc_one.split('\n')

  useEffect(() => {
    if (summary && summary.title !== undefined) {
      if (inputRef.current && inputRef.current.children.length && summary.title && summary.title.toLowerCase() === "universities") {
        inputRef.current.children[1].onclick = function (event) {
          ReactGA.event({
            category: "Universities Page",
            action: "Get in Touch - Click",
          });
        };
      } else if (inputRef.current && inputRef.current.children.length && summary.title && summary.title.toLowerCase() === "employers") {
        inputRef.current.children[1].onclick = function (event) {
          ReactGA.event({
            category: "Employers Page",
            action: "Get in Touch - Click",
          });
        };
      }
    }
  }, []);


  const getVideoId = (url) => {
    const regex = /[?&]v=([a-zA-Z0-9_-]+)/;
    const match = url.match(regex);
  
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }
  // const videoId = getVideoId(summary.description);
  // const pattern = /\[?\*\*|\*\*|"mailto"/g;

  return (
    summary ?
    <StyledWrapper>
      <div className="content">
      <div className="container-fluid margin-top-100">
        <div className="row mission-row">
          <div className="col-12 col-md-6 flex-column justify-content-center padding-left-8 padding-right-8 mission order-2 order-md-1">
            <h1>{summary && summary.title}</h1>
            <p className="subtext"> {summary.intro && summary.intro}</p>
            {/* {summary.description && (
              <p className="subtext" ref={inputRef}>
                {desc(summary.description)}
              </p>
            )} */}
              <div>
                {lines && lines.map((line, index) => {
                  const NewLine = line.replace(/```/g, '');
                  if (line.includes('youtube.com/watch?v=')) {
                    const videoId = getVideoId(line);
                    const widthMatch = /width="(\d+)"/.exec(line);
                    const heightMatch = /height="(\d+)"/.exec(line);
                    
                    const width = widthMatch ? widthMatch[1] : '425'; 
                    const height = heightMatch ? heightMatch[1] : '239';
                    return (
                    <div key={index} className="video-container">
                      <iframe
                      width={width}
                      height={height}
                      src={`https://www.youtube.com/embed/${videoId}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                    ></iframe>
                    </div>
                  );
                    }else {
                  // return <p className="subtext">
                  // {desc(line)}
                  // </p>
                  return (
                    <p>
                     {desc(NewLine)}
                    </p>
                  )
                }
              })}
            </div>

            <a
              target={summary.externalLink ? "_blank" : null}
              className="home-summary-cta"
              href={
                summary.hrefEmail ? `mailto:${summary.hrefEmail}` : summary.href
              }
            >
              <FlatButton>{summary.cta} </FlatButton>
            </a>
            {summary.cta2 && (
              <a
                target="_blank"
                className="home-summary-cta"
                href={
                  summary.hrefEmail2
                    ? `mailto:${summary.hrefEmail2}`
                    : summary.href2
                }
              >
                <FlatButton>{summary.cta2} </FlatButton>
              </a>
            )}
          </div>
          <div className="col-12 col-md-6 order-1 order-md-1">
            {summary.image && (
              <img
                className="img-fluid mx-auto img-size-donate"
                src={mainUrl + summary.image.url}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
      </div>
    </StyledWrapper>
    :
    <StyledWrapper>
    <div className="content">
    {lines && lines.map((line, index) => {
      const NewLine = line.replace(/```/g, '');
      if (line.includes('youtube.com/watch?v=')) {
        const videoId = getVideoId(line);
        const widthMatch = /width="(\d+)"/.exec(line);
        const heightMatch = /height="(\d+)"/.exec(line);
        
        const width = widthMatch ? widthMatch[1] : '425'; 
        const height = heightMatch ? heightMatch[1] : '239';
        return (
        <div key={index} className="video-container">
          <iframe
          width={width}
          height={height}
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        </div>
      );
    } else {
      return <p className="subtext">
      {desc(NewLine)}
      </p>
    }
  })}
</div>
    </StyledWrapper>
  );
};

export default SummaryBlock;
